<template>
  <div class="dialog-container">
    <div class="dialog">
      <div class="header">
        <span class="title">
          {{ email ? $t('email_confirmation') : $t('phone_confirmation') }}
        </span>
        <i
          @click="$emit('close')"
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        ></i>
      </div>
      <div class="content">
        <p>{{ email ? email : phone }}</p>
      </div>
      <div class="footer">
        <button type="button" @click="$emit('close')" class="btn cancel-btn">
          {{ $t('cancel') }}
        </button>
        <button type="button" @click="confirm" class="btn accept-btn">
          {{ $t('send') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  props: ['email', 'phone', 'userId'],
  methods: {
    async confirm() {
      const formData = {
        type: this.email ? 'EMAIL' : 'PHONE'
      }

      try {
        const response = await axios.post(
          `/api_v1/ask_approve/${this.userId}`,
          formData
        )
        const message = this.email
          ? this.$t('users.success_email_send')
          : this.$t('users.success_message_send')

        this.$notify({
          title: this.$t('attention'),
          message,
          type: 'success'
        })
        this.$emit('close')
      } catch (error) {
        this.$notify.error({
          duration: 5000,
          title: `${this.$t('error')} \u{1f62a}`,
          message: error.response.data.message || 'error'
        })
        console.warn('Error approving email or phone.')
      }
    }
  }
}
</script>
<style lang="stylus">
.dialog-container {
  background-color: #00000067;
  width: 100%;
  height: 130%;
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  }
.dialog{
    position: absolute;
    top:30%;
    left:50%;
    transform : translate(-50%,-50%);
    color: #253545;
    width:345px;
    height:224px;
    background: #FFF;

    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 25px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: #446C9D;
    }
    .content{
      padding: 0 25px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
    }
    .footer{
      padding-left:20px;
      padding-right:20px;
      margin-top:20px;
      border-top:1px solid #CAD6DF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      .btn{
        width: 140px;
        height: 32px;
        border-radius: 6px;
        margin-top: 12px;
      }
      .cancel-btn{
        background: #C8D6DF;

      }
      .accept-btn{
        background: #446C9D;;

      }
    }
  }
</style>
